<template>
  <section class="department-table-wrapper">
    <article class="search">
      <chanel-table-search @search="handleSearch" @reset="handleRest" />
    </article>

    <article class="table-wrapper" v-loading="loading">
      <vxe-grid border :data="dataSource" :columns="tableColumn" size="small" v-bind="tableOptions">
        <template #toolbar_buttons>
          <el-button size="small" type="primary" @click="handleAdd">添加渠道</el-button>
          <!-- <el-button size="small" type="default">导入</el-button>
          <el-button size="small" type="default">导出</el-button>-->
        </template>
        <template #customerNum="{ row }">
          <el-link
            style="display: block"
            type="primary"
            :underline="false"
            @click="gotoCustomer(row)"
            >{{ row.customerNum }}</el-link
          >
        </template>
        <template #importBatchNum="{ row }">
          <el-link
            style="display: block"
            type="primary"
            :underline="false"
            @click="gotoBatch(row)"
            >{{ row.importBatchNum }}</el-link
          >
        </template>

        <template #districtName="{ row }">
          {{ row.districtName || '全国' }}
        </template>

        <template #operate="{ row }">
          <el-button type="text" @click="handleEdit(row)">编辑</el-button>
          <el-button type="text" @click="handleShowIsDisableModal(row)">{{
            row.isDisable === 0 ? '停用' : '启用'
          }}</el-button>
          <el-button type="text" @click="handleShowDeleteModal(row)">删除</el-button>
        </template>
        <template #isDisable="{ row }">{{ row.isDisable === 0 ? '启用' : '停用' }}</template>
        <template #level="{ row }">{{ levelText(row.level) }}</template>
        <template #pager>
          <i-pagination
            :page-size="iPagination.pageSize"
            :current-page="iPagination.currentPage"
            :page-sizes="[10, 20, 30, 40]"
            layout="total,prev, pager, next ,sizes,jumper"
            :total="iPagination.total"
            @current-change="handlePageChange"
            @size-change="handleSizeChange"
          />
        </template>
      </vxe-grid>
    </article>
    <edit-channel-modal
      ref="modalForm"
      :channelId="isDisable === 0 ? channelTypeId : ''"
      @ok="modalFormOk"
    />
  </section>
</template>

<script>
import { getDireactionListByKey } from '@/api/dictionary';
import ChanelTableSearch from './ChanelTableSearch.vue';
import IPagination from '@/components/i-pagination/index.vue';
import TableListMixin from '@/minixs/TableListMinix';
import EditChannelModal from './EditChannelModal.vue';

export default {
  name: 'ChannelTable',

  props: {
    channelTypeId: {
      type: String,
      default: ''
    },
    isDisable: {
      type: Number,
      default: 0
    }
  },
  mixins: [TableListMixin],
  components: {
    ChanelTableSearch,
    IPagination,
    EditChannelModal
  },
  data() {
    return {
      levelList: [],
      modalForm: 'modalForm',
      url: {
        list: 'misc-web-api/admin/channel/getChannelPage',
        singleDeleteUrl: 'misc-web-api/admin/channel/delChannel',
        status: 'misc-web-api/admin/channel/disableChannel'
      },
      queryParams: {
        channelTypeId: ''
      },
      tableColumn: [
        { type: 'checkbox', width: 50, fixed: 'left' },
        { field: 'channelId', title: '渠道ID', fixed: 'left', width: 150 },
        { field: 'channelName', title: '渠道名称', width: 200, fixed: 'left' },
        {
          field: 'districtName',
          title: '所属区域',
          width: 300,
          slots: { default: 'districtName' }
        },
        { field: 'userName', title: '负责人', width: 200 },
        {
          field: 'customerNum',
          title: '客户数量',
          slots: { default: 'customerNum' },
          width: 200
        },
        {
          field: 'importBatchNum',
          title: '批次数量',
          slots: { default: 'importBatchNum' },
          width: 200
        },
        { field: 'level', title: '等级', slots: { default: 'level' } },
        { field: 'createTime', title: '添加时间', width: 180 },
        { field: 'isDisable', title: '启用状态', width: 100, slots: { default: 'isDisable' } },
        { title: '操作', slots: { default: 'operate' }, fixed: 'right', width: 200 }
      ],
      tableOptions: {
        toolbarConfig: {
          slots: {
            // 自定义工具栏模板
            buttons: 'toolbar_buttons'
          }
        }
      }
    };
  },
  watch: {
    channelTypeId(newVal) {
      this.queryParams.channelTypeId = newVal;
      this.loadData();
    }
  },
  computed: {
    levelText() {
      return function (val) {
        const data = this.levelList.find((item) => parseInt(item.dictionaryValue, 10) === val);
        return data ? data.dictionaryName : '';
      };
    }
  },
  created() {
    this.getLevelList();
  },
  methods: {
    gotoBatch(e) {
      console.log(e);
      window.open(
        `${window.location.origin}/index/chance/clientFollowUp?channelId=${e.id}&tab=import`
      );
    },
    gotoCustomer(e) {
      console.log(e);
      window.open(
        `${window.location.origin}/index/chance/clientFollowUp?channelId=${e.id}&tab=third`
      );
    },
    handleShowDeleteModal(row) {
      const that = this;
      const params = {
        data: row.id
      };
      const message = '确定要删除吗？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleDelete(params);
        })
        .catch(() => {});
    },

    handleShowIsDisableModal(row) {
      const that = this;
      const params = {
        data: row.id
      };
      const message = row.isDisable === 0 ? '是否确定停用？' : '是否确定启用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    },
    // 获取level 列表
    async getLevelList() {
      try {
        const parqams = {
          data: {
            dictionaryKey: 'channel_level'
          }
        };
        const res = await getDireactionListByKey(parqams);
        this.levelList = res;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>

<style scoped lang="less">
.department-table-wrapper {
  .search {
    padding: 16px 20px 0 20px;
    background: #fff;
  }

  .table-wrapper {
    background: #fff;
    padding: 0 20px;
  }
}
</style>
