import request from '@/utils/request';

// 获取渠道类型树
export function getChanneleTree(data) {
  return request({
    url: "misc-web-api/admin/channel/getChannelTypeTree",
    method: 'POST',
    data,
  })
}
export function getChannelTree(data) {
  return request({
    url: "misc-web-api/admin/channel/getChannelTree",
    method: 'POST',
    data,
  })
}

export default {}
