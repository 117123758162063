<template>
  <section class="department-tree">
    <header class="search">
      <el-form inline size="small" class="form" ref="searchForm" :model="searchForm">
        <el-form-item>
          <el-input
            placeholder="请输入类型名称"
            style="width: 130px"
            v-model="searchForm.channelTypeName"
          />
        </el-form-item>
        <el-form-item prop="isDisable">
          <el-select
            style="width: 140px"
            placeholder="请选择启用状态"
            v-model="searchForm.isDisable"
          >
            <el-option :value="0" label="启用"></el-option>
            <el-option :value="1" label="禁用"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </header>
    <article class="tree-wrapper">
      <el-scrollbar ref="scrollMenuRef" class="scrollMenuBox">
        <el-tree
          ref="tree"
          default-expand-all
          :data="treeData"
          :props="props"
          @node-click="(data) => handleNodeClick(data, 'channelTypeId')"
          class="tree-custom"
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
        >
          <div class="custom-tree-node" slot-scope="{ node, data }">
            <div>
              {{ data.channelTypeName }}
              <span class="status" :class="{ 'status-stop': data.isDisable === 1 }">{{
                data.isDisable === 0 ? '启用' : '停用'
              }}</span>
            </div>
            <el-dropdown
              v-show="checkedId === data.channelTypeId"
              @command="(command) => handleCommand(command, data)"
            >
              <span class="el-dropdown-link">
                <span class="el-icon-setting"></span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command="1">新建下级类型</el-dropdown-item>
                <el-dropdown-item :command="2">新建平级类型</el-dropdown-item>
                <el-dropdown-item :command="3">编辑类型</el-dropdown-item>
                <el-dropdown-item :command="4">启用/停用类型</el-dropdown-item>
                <el-dropdown-item :command="5">删除类型</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-tree>
      </el-scrollbar>
    </article>
    <edit-channel-type-modal @ok="modalFormOk" ref="EditChannelTypeModal" />
  </section>
</template>

<script>
import TreeMinix from '@/minixs/TreeMinix';
import EditChannelTypeModal from './EditChannelTypeModal.vue';

export default {
  name: 'ChannelTree',
  mixins: [TreeMinix],
  components: {
    EditChannelTypeModal
  },
  data() {
    return {
      treeRef: 'tree',
      props: {
        label: 'channelTypeName',
        value: 'channelTypeId',
        child: 'children'
      },
      searchForm: {
        channelTypeName: '',
        isDisable: ''
      },
      url: {
        list: 'misc-web-api/admin/channel/getChannelTypeTree',
        status: 'misc-web-api/admin/channel/disableChannelType',
        del: 'misc-web-api/admin/channel/delChannelType'
      }
    };
  },
  watch: {
    'searchForm.channelTypeName': {
      handler(newVal) {
        this.$refs.tree.filter(newVal);
      }
    },
    'searchForm.isDisable': {
      handler(newVal) {
        console.log(newVal);
        this.$refs.tree.filter(this.searchForm.channelTypeName);
      }
    }
  },
  methods: {
    filterNode(value, data) {
      if (!value) {
        console.log('渠道名称为空', value);
        if (this.searchForm.isDisable === '') {
          return true;
        }
        if (this.searchForm.isDisable === 0) {
          return data.isDisable === 0;
        }
        if (this.searchForm.isDisable === 1) {
          return data.isDisable === 1;
        }
      } else {
        if (this.searchForm.isDisable === '') {
          return data.channelTypeName.indexOf(value) !== -1;
        }
        if (this.searchForm.isDisable === 0) {
          return data.channelTypeName.indexOf(value) !== -1 && data.isDisable === 0;
        }
        if (this.searchForm.isDisable === 1) {
          return data.channelTypeName.indexOf(value) !== -1 && data.isDisable === 1;
        }
      }
    },
    handleDeleteClassify(id) {
      console.log('删除分类', id);
    },
    modalFormOk() {
      this.getTreeList();
    },
    handleCommand(command, data) {
      if (command === 1) {
        // 新增下级渠道类型
        this.$refs.EditChannelTypeModal.form.pid = data.channelTypeId;
        this.$refs.EditChannelTypeModal.add();
      }
      if (command === 2) {
        // 新增平级渠道类型
        this.$refs.EditChannelTypeModal.form.pid = data.pid;
        this.$refs.EditChannelTypeModal.add(data);
      }
      if (command === 3) {
        this.$refs.EditChannelTypeModal.edit(data);
      }
      if (command === 4) {
        // 停用启用
        this.handleShowIsDisableModal(data);
      }
      if (command === 5) {
        // 删除渠道类型\
        this.handleShowDeleteModal(data);
      }
    },
    // 启用
    handleShowIsDisableModal(row) {
      const that = this;
      const params = {
        data: row.channelTypeId
      };
      const message = row.isDisable === 0 ? '是否确定停用？' : '是否确定启用？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleSingleSetIsDisable(params);
        })
        .catch(() => {});
    },
    // 删除
    handleShowDeleteModal(row) {
      const that = this;
      const params = {
        data: row.channelTypeId
      };
      const message = '确定要删除吗？';
      this.$confirm(message, '确认提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.handleDelete(params);
        })
        .catch(() => {
          // this.$message({
          //   type: 'info',
          //   message: '已取消删除'
          // });
        });
    }
  }
};
</script>

<style scoped lang="less">
.department-tree {
  display: flex;
  flex-direction: column;
  height: 100%;
  .search {
    background: #fff;
    padding: 0px 20px 0px 20px;
    box-sizing: border-box;
    border-bottom: 1px solid #eaeaea;
  }
}

.tree-custom {
  /deep/ .el-tree-node__content {
    height: 40px !important;

    .custom-tree-node {
      padding-right: 10px;
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100% !important;
      font-size: 14px;

      .status {
        margin-right: 10px;
        color: #00a199;
      }

      .status-stop {
        margin-right: 10px;
        color: #e86247;
      }

      .el-icon-setting {
        position: absolute;
        width: 14px;
        height: 14px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #2861e2;
      }
    }
    ::v-deep.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
      background-color: #e9effc;
    }
  }
}
.tree-wrapper {
  flex: 1;
  overflow: hidden;
}
.scrollMenuBox {
  width: 100%;
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
</style>
